import { datadogRum } from "@datadog/browser-rum";
import { useEffect } from "react";
import { useMaybeOrder } from "services/orders/OrderLoaderContext";

// https://docs.datadoghq.com/real_user_monitoring/browser/modifying_data_and_context/?tab=npm#identify-user-session
export function DatadogUser() {
  const order = useMaybeOrder();
  useEffect(() => {
    if (order) {
      datadogRum.setUserProperty("orderId", order.id);
      datadogRum.setUserProperty("customerId", order.customer.id);
    }
  }, [order]);

  return null;
}
